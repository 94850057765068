import classNames from "classnames"
import "./headerMenu.css"
import { Link, useLocation } from "wouter"
import { useWatcher } from "../../../lib/state/useWatcher"
import { userState } from "../../../store/user"
import { UserIcon } from "../../button/IconButton"

const items = [
  {
    label: "Mani uzdevumi",
    url: "/dashboard",
  },
  { label: "Admin", url: "/admin/dashboard", isAdmin: true },
  { label: UserIcon, url: "/me", icon: true },
]

export const HeaderMenu = () => {
  const [location] = useLocation()
  const user = useWatcher(userState)

  return (
    <div className="header-menu">
      <div className="items">
        {items.map((item) => {
          if (item.isAdmin && user.role < 2) {
            return null
          }
          return (
            <Link to={item.url} key={item.url}>
              <div
                className={classNames(
                  "item",
                  location.startsWith(item.url) && "active",
                  item.icon && "is-icon",
                )}
              >
                {item.label}
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
