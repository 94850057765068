import styles from "./emptyscreen.module.css"

export const EmptyScreen = ({ title }: { title?: string }) => {
  return (
    <div className={styles.emptyScreen}>
      <img src="/assets/empty.png" alt="empty" />
      <p>{title || "Šobrīd nekā nav"}</p>
    </div>
  )
}
