import queryString from "query-string"
import { getMyWorksheet } from "../../../fetch/users"
import { formatDate } from "../../../lib/formatDate"
import { useFetchHook } from "../../../lib/hooks/fetchHook"
import { Dates } from "./Dates"
import styles from "./userview.module.css"
import { useState } from "react"
import { useLocation, useSearch } from "wouter"
import { Task } from "../../../../types"
import { HistoryItem } from "../projectView/task/HistoryItem"
import styled from "styled-components"
import { InfoBlock } from "../../../components/infoBlock/InfoBlock"
import { formatTimeDelta } from "../../../lib/formatTimeDelta"
import { EmptyScreen } from "../../../components/emptyScreen/EmptyScreen"

const HistoryItems = styled.div`
  max-width: 500px;
  min-width: 400px;
  align-self: center;
`

const Blocks = styled.div`
  display: grid;
  grid-auto-columns: 200px;
  grid-auto-flow: column;
  gap: 8px;
  justify-content: center;
`

export const Worksheet = () => {
  const [location, setLocation] = useLocation()
  const search = useSearch()

  const initialFilters = queryString.parse(search)
  const d =
    typeof initialFilters.date === "string"
      ? initialFilters.date
      : formatDate(new Date())
  const [selectedDate, setSelectedDate] = useState<string>(d)
  const { data: worksheet } = useFetchHook<
    Record<
      string,
      { tasks: Task[]; salaryDone: number; timeUsed: number; unitsDone: number }
    >
  >(getMyWorksheet, {})
  const onDateChange = (next: string) => {
    const str = queryString.stringify({ date: next })
    setLocation(location + "?" + str)
    setSelectedDate(next)
  }
  const currentDate = worksheet?.[selectedDate]
  return (
    <div className={styles.root}>
      <h2>Mana darba lapa</h2>
      <Dates selected={selectedDate} onSelect={onDateChange} />
      {currentDate ? (
        <>
          <Blocks>
            {currentDate.salaryDone ? (
              <InfoBlock
                title={currentDate.salaryDone / 100 + "€"}
                description="Nopelnītais"
              />
            ) : null}
            {currentDate.timeUsed ? (
              <InfoBlock
                title={formatTimeDelta(currentDate.timeUsed)}
                description="Patērētais laiks"
              />
            ) : null}

            {currentDate.unitsDone ? (
              <InfoBlock title={currentDate.unitsDone} description="Vienības" />
            ) : null}
          </Blocks>
          <HistoryItems>
            {worksheet?.[selectedDate]?.tasks.map((c) => (
              <HistoryItem task={c} />
            ))}
          </HistoryItems>
        </>
      ) : (
        <EmptyScreen />
      )}
    </div>
  )
}
