import React from "react"
import { createPortal } from "react-dom"

export const Portal = ({
  id,
  children,
}: {
  id: string
  children: React.ReactNode
}) => {
  const domNode = document.getElementById(id) || document.body

  if (!children || !domNode) {
    return null
  }
  return <>{createPortal(children, domNode)}</>
}
