import { Task } from "../../types"
import { prepareQuery } from "../lib/prepareQuery"
import { localFetch } from "./localFetch"

export const getTasks = (filters: Record<string, any>) => {
  const q = prepareQuery(filters)
  return localFetch(`/api/views/tasks${q}`)
}

export const importTask = (body: unknown) => {
  return localFetch(`/api/views/import`, {
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const finishTask = (
  taskId: number,
  body: {
    status: number
    timeUsed?: number
    unitsUsed?: number
    salaryUsed?: number
    sumUsed?: number
  },
) => {
  return localFetch(`/api/tasks/${taskId}`, {
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const finishOperation = (body: {
  date: string
  timeUsed: number
  operationId: number
  comment: string
}) => {
  return localFetch(`/api/tasks`, {
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const updateTask = (
  taskId: number,
  body: {
    timeUsed?: number
    unitsUsed?: number
    salaryUsed?: number
    sumUsed?: number
  },
) => {
  return localFetch(`/api/tasks/${taskId}`, {
    body: JSON.stringify(body),
    method: "PATCH",
  })
}

export const deleteTask = (taskId: Task["id"]) => {
  return localFetch(`/api/tasks/${taskId}`, { method: "DELETE" })
}

export const reportDefect = (body: {
  operationId: number
  comment: string
  target: string
}) => {
  return localFetch(`/api/tasks/register-defect`, {
    method: "POST",
    body: JSON.stringify(body),
  })
}
