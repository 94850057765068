import { PropsWithChildren, useState } from "react"
import { Portal } from "../portal/Portal"
import styled from "styled-components"
import { Button } from "../button/Button"

const Background = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ConfirmModal = styled.div`
  padding: 16px 20px;
  background: #fff;
  min-height: 50px;
  min-width: 400px;
  border-radius: 20px;
`
const ConfirmBody = styled.div`
  margin-bottom: 16px;
`
const ConfirmBottom = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`

type Props = {
  Trigger: JSX.ElementType
  onConfirm?: () => Promise<unknown>
}

export const Confirm = ({
  Trigger,
  onConfirm,
  children,
}: PropsWithChildren<Props>) => {
  const [modalVisible, showModal] = useState(false)

  const handleConfirm = async () => {
    try {
      if (onConfirm) {
        await onConfirm()
        showModal(false)
      }
    } catch (err) {
      //
    }
  }
  return (
    <>
      {modalVisible && (
        <Portal id="">
          <Background>
            <ConfirmModal>
              <ConfirmBody>{children}</ConfirmBody>
              <ConfirmBottom>
                <Button variant="secondary" onClick={() => showModal(false)}>
                  Atcelt
                </Button>
                <Button onClick={handleConfirm}>Apstiprināt</Button>
              </ConfirmBottom>
            </ConfirmModal>
          </Background>
        </Portal>
      )}

      <Trigger onClick={() => showModal(true)} />
    </>
  )
}
