import "./projectOperation.css"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "wouter"
import { getProjectOperations } from "../../../fetch/projects"
import { ProjectHeading } from "./ProjectHeading"
import { Group, Operation, Project } from "../../../../types"
import classNames from "classnames"
import { BreadCrumbs } from "../../../components/breadcrumbs/BreadCrumbs"
import { OpenOperation } from "./OpenOperation"
import { isBitus } from "../../../../config/urls"
import { useWatcher } from "../../../lib/state/useWatcher"
import { TenantFeature, tenantState } from "../../../store/tenant"

const formatTime = (ms: number) => {
  return `${Math.floor(ms / 60)}h ${ms % 60}min`
}

export const ProjectOperation = ({
  id,
  name,
}: {
  id?: string
  name?: string
}) => {
  const {
    settings: { features },
  } = useWatcher(tenantState)
  const { projectId } = useParams()
  const [selectedOperation, setSelectedOperation] = useState<Operation>()

  const [project, setProject] = useState<Project | undefined>()
  const [groups, setGroups] = useState<Group[] | undefined>()

  const getUnits = (val: number, units?: boolean) => {
    if (units) {
      return val
    }
    return val ? val / 100 + " eur" : 0
  }

  const init = useCallback(() => {
    if (!id || !projectId) {
      return
    }
    getProjectOperations(id, projectId).then(async (d) => {
      const json = await d.json()
      setProject(json.project)
      setGroups(json.groups)
    })
  }, [id, projectId])

  useEffect(() => {
    init()
  }, [init])

  return (
    <>
      <BreadCrumbs
        items={[
          { path: "/../", title: "Projekti" },
          { path: `/../${id}`, title: name ?? "" },

          { path: `/../${id}/${projectId}`, title: project?.name ?? "" },
        ]}
      />
      <OpenOperation
        operation={selectedOperation}
        onClose={() => setSelectedOperation(undefined)}
        subProjectId={projectId}
        projectId={id}
      />
      {project && (
        <ProjectHeading
          project={project}
          features={features}
          showOpEdit
          onSave={init}
        />
      )}
      {groups?.map((group, i) => (
        <div className="group" key={i}>
          <div className="title">{group.name}</div>
          {group.operations.map((operation, j) => (
            <div
              className="operation"
              key={j}
              onClick={() => setSelectedOperation(operation)}
            >
              <div className="operation-title">
                <div
                  className={classNames("status", `status-${operation.status}`)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                {operation.name}
              </div>
              <div>
                {!isBitus && (
                  <>
                    {formatTime(operation.timeUsed)} (
                    {formatTime(operation.timePredicted)}){" | "}
                  </>
                )}

                {features[TenantFeature.Units] && (
                  <>
                    {getUnits(operation.unitsUsed, true)}
                    <> / </>
                    {getUnits(operation.unitsPredicted, true)}{" | "}
                  </>
                )}
                {features[TenantFeature.Salary] && (
                  <>
                    {getUnits(operation.salaryUsed)}
                    <> / </>
                    {getUnits(operation.salaryPredicted)}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  )
}
