import { ComponentProps, useCallback, useMemo } from "react"
import { InfoBlock } from "../../../components/infoBlock/InfoBlock"
import { InfoBlockWrap } from "../../../components/infoBlock/InfoBlockWrap"
import { Table } from "../../../components/table/Table"
import { getErrors } from "../../../fetch/errors"
import { formatTime } from "../../../lib/formatTime"
import { isBitus } from "../../../../config/urls"
import { Link } from "wouter"
import { Button } from "../../../components/button/Button"
import { toast } from "react-toastify"
import { finishTask } from "../../../fetch/tasks"
import { Task, TaskStatus } from "../../../../types"
import { useFetchHook } from "../../../lib/hooks/fetchHook"

const columns = [
  { title: "Darbinieks", id: "workerName" },
  { title: isBitus ? "Sērija" : "Projekts", id: "projectName" },
  { title: isBitus ? "Iecirknis" : "Subprojekts", id: "subProjectName" },
  { title: isBitus ? "Detaļa" : "Process", id: "operationName" },
  { title: "Tips", id: "id" },
  { title: "Datums", id: "createdAt" },
  { title: "Komentārs", id: "comment" },
  { title: "Nosūtīt uz", id: "target" },
  // { title: "Zaudējumi", id: "expenseLoses" },
  { title: "", id: "status" },
]

type Row = ComponentProps<typeof Table>["rows"]

export const AdminErrors = () => {
  // const [data, setData] = useState<ComponentProps<typeof InfoBlock>[]>([])
  const { data, reload } = useFetchHook<
    { items: Task[]; info: ComponentProps<typeof InfoBlock>[] },
    Record<string, any>
  >(getErrors, { defaultValue: { items: [], info: [] } })

  const confirmTask = useCallback(
    async (
      e: React.MouseEvent<HTMLButtonElement>,
      id: number,
      status: TaskStatus
    ) => {
      e.preventDefault()
      e.stopPropagation()
      await finishTask(id, {
        status:
          status === TaskStatus.Error
            ? TaskStatus.ErrorAck
            : TaskStatus.Approved,
      })

      toast("Uzdevums veiksmīgi apstiprināts!", { type: "success" })
      reload()
    },
    [reload]
  )

  const tableData = useMemo(() => {
    const rows: ComponentProps<typeof Table>["rows"] = []
    data?.items.forEach((row) => {
      const curr: Row[number] = { id: row.id, deletedAt: row.deletedAt }

      columns.forEach(({ id }) => {
        switch (id) {
          case "workerName": {
            const value = row[id]
            curr[id] =
              // <Link to={`/../employees/${row.project.id}`}>{value.name}</Link>
              value
            break
          }
          case "projectName": {
            const value = row[id]
            curr[id] = <Link to={`/../projects/${row.projectId}`}>{value}</Link>
            break
          }
          case "id": {
            if (
              row.status === TaskStatus.Error ||
              row.status === TaskStatus.ErrorAck
            ) {
              curr.error = true
              curr[id] = "Reģistrēts defekts"
            } else {
              curr[id] = "Kļūdas labojums"
            }
            break
          }

          case "subProjectName":
          case "operationName": {
            const value = row[id]
            curr[id] = (
              <Link to={`/../projects/${row.projectId}/${row.subProjectId}`}>
                {value}
              </Link>
            )
            break
          }
          case "createdAt": {
            const value = row[id]
            curr[id] = value ? formatTime(value) : "-"
            break
          }
          case "comment": {
            const value = row[id]
            curr[id] = value
            break
          }

          case "target": {
            const value = row[id]
            curr[id] = value
            break
          }

          case "status": {
            const value = row[id] as unknown as number
            if (
              value === TaskStatus.Approved ||
              value === TaskStatus.ErrorAck
            ) {
              curr[id] = "Apstiprināts"
              break
            }
            curr[id] = (
              <Button onClick={(e) => confirmTask(e, row.id, row.status)}>
                Apstiprināt
              </Button>
            )
            break
          }
        }
      })
      rows.push(curr)
    })
    return rows
  }, [confirmTask, data?.items])

  return (
    <>
      <InfoBlockWrap>
        {data?.info.map((block, i) => (
          <InfoBlock {...block} key={i} />
        ))}
      </InfoBlockWrap>
      <Table columns={columns} rows={tableData} />
    </>
  )
}
