import { useLocation } from "wouter"
import { Button } from "../../../components/button/Button"
import { InfoBlock } from "../../../components/infoBlock/InfoBlock"
import { logout } from "../../../fetch/login"
import { getMyProfile } from "../../../fetch/users"
import { formatTimeDelta } from "../../../lib/formatTimeDelta"
import { useFetchHook } from "../../../lib/hooks/fetchHook"
import { useWatcher } from "../../../lib/state/useWatcher"
import { userState } from "../../../store/user"
import styles from "./userview.module.css"
import styled from "styled-components"

const Blocks = styled.div`
  display: grid;
  grid-auto-columns: 200px;
  grid-auto-flow: column;
  gap: 8px;
  justify-content: center;
`

type Profile = {
  salaryDone?: number
  timeUsed?: number
  unitsDone?: number
}

export const UserView = () => {
  const me = useWatcher(userState)
  const [, setLocation] = useLocation()

  const { data: profile } = useFetchHook<Profile>(getMyProfile, {
    defaultValue: {},
  })

  const handleLogout = async () => {
    await logout()
    window.location.reload()
  }
  return (
    <div className={styles.root}>
      <h1>{me.name}</h1>
      <Blocks>
        {profile.salaryDone ? (
          <InfoBlock
            title={profile.salaryDone / 100 + "€"}
            description="Nopelnītais"
          />
        ) : null}
        {profile.timeUsed ? (
          <InfoBlock
            title={formatTimeDelta(profile.timeUsed)}
            description="Patērētais laiks"
          />
        ) : null}

        {profile.unitsDone ? (
          <InfoBlock title={profile.unitsDone} description="Vienības" />
        ) : null}
      </Blocks>

      <Button onClick={() => setLocation("/me/worksheet")} variant="secondary">
        Mana darba lapa
      </Button>
      <Button onClick={handleLogout} variant="inline">
        Iziet no sistēmas
      </Button>
    </div>
  )
}
