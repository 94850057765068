import { Redirect, Route, Switch } from "wouter"
import { Tab, Tabs } from "../../../components/tabs/Tabs"
import { AdminTasks } from "../AdminTasks/AdminTasks"
import { AdminProjects } from "../AdminProjects/AdminProjects"
import { AdminOperations } from "../AdminOperations/AdminOperations"
import { AdminDistricts } from "../AdminDistricts/AdminDistricts"
import { AdminUsers } from "../AdminUsers/AdminUsers"
import { AdminErrors } from "../AdminErrors/AdminErrors"
import { AdminImport } from "../AdminImport/AdminImport"
import { isBitus } from "../../../../config/urls"
import { CreateUser } from "../AdminUsers/CreateUser"
import { TenantFeature } from "../../../store/tenant"

const tabs: Tab[] = [
  {
    id: "/tasks",
    title: "Uzdevumi",
    Component: AdminTasks,
  },
  {
    id: "/projects",
    title: isBitus ? "Sērijas" : "Projekti",
    Component: AdminProjects,
  },
  {
    id: "/process",
    title: isBitus ? "Tirgi" : "Procesi",
    Component: AdminOperations,
  },
  {
    id: "/districts",
    title: "Iecirkņi",
    Component: AdminDistricts,
    features: [TenantFeature.Districts],
  },
  {
    id: "/employees/create",
    title: "Darbinieki",
    Component: CreateUser,
    hideInTabs: true,
  },
  {
    id: "/employees",
    title: "Darbinieki",
    Component: AdminUsers,
  },
  {
    id: "/faults",
    title: "Kļūdas",
    Component: AdminErrors,
  },
  {
    id: "/import",
    title: "Importēt",
    Component: AdminImport,
  },
]

export const AdminDashboard = () => {
  return (
    <div style={{ padding: "0 40px 60px" }}>
      <Tabs tabs={tabs} />
      <Switch>
        {tabs.map(({ id, Component, title }) => (
          <Route path={id} key={id} nest>
            {Component ? <Component /> : title}
          </Route>
        ))}
        <Redirect to="/tasks" />
      </Switch>
    </div>
  )
}
