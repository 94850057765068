import classNames from "classnames"
import "./tabs.css"
import { Link, useLocation } from "wouter"
import { useWatcher } from "../../lib/state/useWatcher"
import { TenantFeature, tenantState } from "../../store/tenant"

export type Tab = {
  id: string
  title: string
  hideInTabs?: boolean
  features?: TenantFeature[]
  Component?: any // TODO :D
}
type Props = {
  tabs: Tab[]
}

export const Tabs = ({ tabs }: Props) => {
  const [location] = useLocation()
  const {
    settings: { features },
  } = useWatcher(tenantState)
  return (
    <div className="tabs wrap">
      <div className="tabs-inner">
        {tabs.map((tab) => {
          if (tab.hideInTabs) {
            return null
          }
          if (
            tab.features &&
            !tab.features.every((feature) => features[feature])
          ) {
            return null
          }
          return (
            <Link to={tab.id} key={tab.id}>
              <div
                className={classNames("tab", {
                  active: location.startsWith(tab.id),
                })}
              >
                {tab.title}
              </div>
            </Link>
          )
        })}
      </div>
      <div id="tabs-right" />
    </div>
  )
}
