import { useCallback, useEffect, useState } from "react"

export function useFetchHook<T = {}, P = {}>(
  method: (params: P) => Promise<Response>,
  {
    params,
    defaultValue,
  }: {
    params?: P
    defaultValue?: T
  },
) {
  const [data, setData] = useState<T>(defaultValue as T)

  const init = useCallback(async () => {
    try {
      const res = await method(params as P)
      if (!res.ok) {
        throw new Error()
      }
      const json = await res.json()
      setData(json)
    } catch (err) {
      //
      console.log(err)
    }
  }, [method, params])

  useEffect(() => {
    init()
  }, [init, method, params])

  return { data, reload: init }
}
