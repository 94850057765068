export const getTenantSlug = () => window.location.hostname.split(".")[0]

const isLocalDev = window.location.hostname.includes(".localhost")
const isDev = window.location.hostname.includes(".local.")
const isStg = window.location.hostname.includes("stg.")
export const getEnvironmentUrl = () => (isDev || isStg ? "stg." : "")
export const HOST = isLocalDev
  ? `http://${getTenantSlug()}.localhost:9001`
  : `https://${getTenantSlug()}.${getEnvironmentUrl()}productionline.io`

export const isBitus = getTenantSlug() === "bitus"
export const isRazotne = getTenantSlug() === "razotne"
