import { useEffect, useState } from "react"
import { Button } from "../../../components/button/Button"
import { Sidepanel } from "../../../components/sidepanel/Sidepanel"
import { Select } from "../../../components/form/select"
import { HOST } from "../../../../config/urls"
import { exportMonth } from "../../../fetch/export"

const m = [
  "Janvāris",
  "Februāris",
  "Marts",
  "Aprīlis",
  "Maijs",
  "Jūnijs",
  "Jūlijs",
  "Augusts",
  "Septembris",
  "Oktobris",
  "Novembris",
  "Decembris",
]
const months = new Array(12)
  .fill(null)
  .map((_, i) => ({ id: i + 1, name: m[i] }))

const currentMonth = new Date().getMonth() + 1

export const Export = () => {
  const [showExportModal, setShowExportModal] = useState(false)
  const [month, setSelectedMonth] = useState(currentMonth)

  useEffect(() => {
    setSelectedMonth(currentMonth)
  }, [showExportModal])

  const startExport = () => {
    exportMonth(month)
  }
  return (
    <>
      <Sidepanel
        open={showExportModal}
        onClose={() => setShowExportModal(false)}
      >
        <Select
          value={month}
          label="Mēnesis"
          options={months}
          onChange={(e) => setSelectedMonth(+e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <Button onClick={startExport}>Eksportēt</Button>
      </Sidepanel>
      <Button
        onClick={() => setShowExportModal(true)}
        style={{ marginRight: 8 }}
      >
        Eksportēt
      </Button>
    </>
  )
}
