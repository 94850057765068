import { Route, Switch, useLocation, useParams } from "wouter"
import "./individualProject.css"
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Table } from "../../../components/table/Table"
import { getProject } from "../../../fetch/projects"
import { Project } from "../../../../types"
import { ProjectOperation } from "./ProjectOperation"
import { ProjectHeading } from "./ProjectHeading"
import { BreadCrumbs } from "../../../components/breadcrumbs/BreadCrumbs"
import { Status } from "../../../components/status/Status"
import { isBitus } from "../../../../config/urls"
import { useWatcher } from "../../../lib/state/useWatcher"
import { TenantFeature, tenantState } from "../../../store/tenant"
import { EditOperation } from "./EditOperation"

const columns = [
  { title: "Status", id: "status" },
  { title: "Normatīvi", id: "difficulty" },
  { title: isBitus ? "Iecirknis" : "Projekts", id: "name" },
  { title: "Paredzētais laiks", id: "timePredicted" },
  { title: "Patērētais laiks", id: "timeUsed" },
  { title: "Prognozētās", id: "unitsPredicted" },
  { title: "Zaudējumi", id: "salaryDiff" },
  { title: "Vienības", id: "unitsUsed" },
  { title: "Summa", id: "salary" },
  { title: "", id: "edit" },
]

type Row = ComponentProps<typeof Table>["rows"]

export const IndividualProject = () => {
  const [, setLocation] = useLocation()
  const {
    settings: { features },
  } = useWatcher(tenantState)
  const { id } = useParams()

  const [project, setProject] = useState<Project | undefined>()
  const [tableData, setTableData] = useState<
    ComponentProps<typeof Table>["rows"]
  >([])
  const init = useCallback(() => {
    if (!id) {
      return
    }
    getProject(id).then(async (d) => {
      const json = await d.json()
      setProject(json.project)
      setTableData(prepareTableData(json.items))
    })
  }, [id])

  useEffect(() => {
    init()
  }, [id, init])

  const prepareTableData = (data: Record<string, any>[]) => {
    const rows: ComponentProps<typeof Table>["rows"] = []
    data.forEach((row) => {
      const curr: Row[number] = { id: row.id }
      const progress = features[TenantFeature.Units] ? row.progress : row.timeProgress
      columns.forEach(({ id }) => {
        const value = row[id]
        switch (id) {
          case "status": {
            curr[id] = (
              <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <Status status={row.status} />
                <span style={{ fontSize: 20, fontWeight: 500 }}>
                  {progress}%
                </span>
              </div>
            )
            break
          }
          case "difficulty": {
            curr[id] = (
              <span
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  color: row.difficulty > 100 ? "var(--error)" : undefined,
                }}
              >
                {row.difficulty}%
              </span>
            )
            break
          }
          case "name": {
            curr[id] = value
            break
          }
          case "timePredicted":
          case "timeUsed": {
            curr[id] = value
              ? `${Math.floor(value / 60)}h ${value % 60}min`
              : "-"
            break
          }
          case "salaryDiff": {
            const val = value ? `${value / 100}€` : "-"
            if (value < 0) {
              curr.error = true
            }
            curr[id] = (
              <span style={{ color: value < 0 ? "var(--error)" : "" }}>
                {val}
              </span>
            )
            break
          }
          case "salary": {
            const v = row["salaryUsed"]
            curr[id] = v ? `${v / 100}€` : "-"
            break
          }
          case "unitsPredicted":
          case "unitsUsed": {
            curr[id] = value
            break
          }
          case "edit": {
            curr[id] = <EditOperation project={row as Project} onSave={init} />
          }
        }
      })
      rows.push(curr)
    })
    return rows
  }

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => {
      if (col.id === "timeUsed") {
        return features[TenantFeature.Time]
      }

      if (col.id === "unitsUsed") {
        return features[TenantFeature.Units]
      }

      if (col.id === "salary" || col.id === "salaryDiff") {
        return features[TenantFeature.Salary]
      }

      if (col.id === "difficulty") {
        return features[TenantFeature.Normatives]
      }

      return true
    })
  }, [features])

  return (
    <Switch>
      <Route path="/:projectId">
        <ProjectOperation id={id} name={project?.name} />
      </Route>
      <Route>
        <div>
          <BreadCrumbs
            items={[
              { path: "/../", title: "Projekti" },
              { path: `/../${project?.id}`, title: project?.name ?? "" },
            ]}
          />
          {project && (
            <ProjectHeading
              project={project}
              features={features}
              showEdit
              onSave={init}
            />
          )}
          <Table
            columns={filteredColumns}
            rows={tableData}
            onRowClick={(id) => setLocation(`/${id}`)}
          />
        </div>
      </Route>
    </Switch>
  )
}
