import { SelectHTMLAttributes } from "react"
import styles from "./input.module.css"

type Option = { id: string | number | undefined; name: string }

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  options: Option[]
  label?: string
}

export const Select = (props: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange?.({
      ...e,
      target: {
        ...e.target,
        // @ts-ignore
        value: e.target.value === "undefined" ? undefined : e.target.value,
        selectedOptions: e.target.selectedOptions,
      },
    })
  }
  return (
    <div className={styles.inputWrap}>
      <label>
        {props.label && <span>{props.label}</span>}
        <select className={styles.input} {...props} onChange={handleChange}>
          <option disabled value="0" />
          {props.options.map((opt) => (
            <option key={opt.id ?? "undefined"} value={opt.id ?? "undefined"}>
              {opt.name}
            </option>
          ))}
        </select>
      </label>
    </div>
  )
}
