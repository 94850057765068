import { ComponentProps, useEffect, useState } from "react"
import { Table } from "../../../components/table/Table"
import { getUsers } from "../../../fetch/users"
import { Button } from "../../../components/button/Button"
import styles from "./adminusers.module.css"
import { useLocation } from "wouter"
import { Worker } from "../../../../types"
import { EditUser } from "./EditUser"
import { useWatcher } from "../../../lib/state/useWatcher"
import { tenantState } from "../../../store/tenant"

const columns = [
  { title: "Darbinieks", id: "name" },
  { title: "Lietotājvārds", id: "username" },
  // { title: "Koeficients", id: "coeficient" },
  // { title: "Kļūdas", id: "errorCount" },
  { title: "Administrators", id: "isAdmin" },
  // { title: "Mainīt paroli", id: "changePasswordUrl" },
  { title: "", id: "edit" },
]

type Row = ComponentProps<typeof Table>["rows"]

const prepareTableData = (
  data: Worker[],
  setEdit: (worker: Worker) => void
) => {
  const rows: ComponentProps<typeof Table>["rows"] = []
  data.forEach((row) => {
    const curr: Row[number] = { id: row.id }

    columns.forEach(({ title, id }) => {
      switch (id) {
        case "changePasswordUrl": {
          if (row[id]) {
            curr[id] = <a href={row[id]}>Kopēt saiti</a>
          } else {
            curr[id] = <a href="~/#">Izveidot Linku</a>
          }
          break
        }
        case "edit": {
          curr[id] = (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                setEdit(row)
              }}
            >
              Labot
            </a>
          )
          break
        }
        case "isAdmin": {
          curr[id] = row.role >= 2 ? "✅" : "-"
          break
        }
        default: {
          curr[id] = row[id as keyof Worker] as any
          break
        }
      }
    })
    rows.push(curr)
  })
  return rows
}

export const AdminUsers = () => {
  const {
    settings: { features },
  } = useWatcher(tenantState)
  const [, setLocation] = useLocation()
  const [editRow, setEdit] = useState<undefined | Worker>()
  const [_workers, setWorkers] = useState<Worker[]>([])
  const [tableData, setTableData] = useState<
    ComponentProps<typeof Table>["rows"]
  >([])

  const init = () => {
    getUsers().then(async (d) => {
      const json = await d.json()
      setWorkers(json.items as Worker[])
      setTableData(prepareTableData(json.items as Worker[], setEdit))
    })
  }

  useEffect(() => {
    init()
  }, [])

  // const onDelete = (id: number) => {
  //   deleteDistrict(id).then(init)
  // }

  const onClose = (updated?: boolean) => {
    setEdit(undefined)
    updated && init()
  }

  function setEditValue<k extends keyof Worker>(field: k, value: Worker[k]) {
    if (editRow != null) {
      setEdit({ ...editRow, [field]: value })
    }
  }

  return (
    <>
      <div className={styles.addBtnWrap}>
        <Button onClick={() => setLocation("/create")}>
          Pievienot darbinieku
        </Button>
      </div>
      <Table columns={columns} rows={tableData} />
      <EditUser
        data={editRow}
        onClose={onClose}
        setEditValue={setEditValue}
        features={features}
      />
    </>
  )
}
