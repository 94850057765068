import classNames from "classnames"
import { useEffect, useState } from "react"
import { Operation } from "../../../../types"
import { Button } from "../../../components/button/Button"
import { StatusText } from "../../../components/status/Status"
import { getOperation } from "../../../fetch/projects"
import { formatTime } from "../../../lib/formatTime"
import { formatTimeDelta } from "../../../lib/formatTimeDelta"
import styles from "../AdminTasks/sidepanel.module.css"
import { useWatcher } from "../../../lib/state/useWatcher"
import { TenantFeature, tenantState } from "../../../store/tenant"

type Props = {
  operation?: Operation
  onClose: () => void
  subProjectId?: string
  projectId?: string
}

type OpRes = {
  expenseSum: number
  id: number
  name: string
  status: number
  successor_id: number
  tasks: {
    unitsUsed: number
    salaryUsed: number
    createdAt: string
    id: number
    timeUsed: number
    user: {
      name: string
      id: string
    }
  }[]
  timePredicted: number
  timeUsed: number
  unitsPredicted: number
  unitsUsed: number
  workerCount: number
}
export const OpenOperation = ({
  operation,
  onClose,
  subProjectId,
  projectId,
}: Props) => {
  const {
    settings: { features },
  } = useWatcher(tenantState)
  const [op, setOp] = useState<OpRes>()

  const formatUnits = (unit?: number, asUnits?: boolean) => {
    if (!unit) {
      return 0
    }
    return asUnits ? unit : `${(unit / 100).toFixed(2)}€`
  }

  useEffect(() => {
    if (subProjectId && projectId && operation?.id) {
      getOperation(projectId, subProjectId, operation?.id).then(async (res) => {
        const json = await res.json()
        setOp(json)
      })
    }
  }, [operation?.id, projectId, subProjectId])

  const blocksContainerClass =
    features[TenantFeature.Units] &&
    features[TenantFeature.Salary] &&
    features[TenantFeature.Time]
      ? "blocks3"
      : "blocks"

  return (
    <div className={classNames(styles.sidepanel, operation ? styles.open : "")}>
      <div className={styles.wrap}>
        <div className={styles.close}>
          <Button variant="inline" onClick={onClose}>
            Aizvērt
          </Button>
        </div>
        <div className={styles.title}>{operation?.name}</div>

        <StatusText status={operation?.status} />
        <div className={styles[blocksContainerClass]}>
          {features[TenantFeature.Units] && (
            <div className={styles.taskBlock}>
              <div className={styles.taskRow}>
                <div className={styles.taskRowTitle}>
                  {formatUnits(operation?.unitsUsed, true)}
                </div>
                <div className={styles.taskRowSub}>Vienības</div>
              </div>
              <div className={styles.taskRow}>
                <div className={styles.taskRowTitle}>
                  {formatUnits(operation?.unitsPredicted, true)}
                </div>
                <div className={styles.taskRowSub}>Paredzētās vienības</div>
              </div>
            </div>
          )}
          {features[TenantFeature.Salary] && (
            <div className={styles.taskBlock}>
              <div className={styles.taskRow}>
                <div className={styles.taskRowTitle}>
                  {formatUnits(operation?.salaryUsed)}
                </div>
                <div className={styles.taskRowSub}>Nopelnītais</div>
              </div>

              <div className={styles.taskRow}>
                <div className={styles.taskRowTitle}>
                  {formatUnits(operation?.salaryPredicted)}
                </div>
                <div className={styles.taskRowSub}>Summa</div>
              </div>
            </div>
          )}
          {features[TenantFeature.Time] && (
            <div className={styles.taskBlock}>
              <div className={styles.taskRow}>
                <div className={styles.taskRowTitle}>
                  {formatTimeDelta(operation?.timeUsed)}
                </div>
                <div className={styles.taskRowSub}>Reālais laiks</div>
              </div>
              <div className={styles.taskRow}>
                <div className={styles.taskRowTitle}>
                  {formatTimeDelta(operation?.timePredicted)}
                </div>
                <div className={styles.taskRowSub}>Paredzētais laiks</div>
              </div>
            </div>
          )}
        </div>

        {op && (
          <table>
            <thead>
              <tr>
                <td>Darbinieks</td>
                <td>Pavadītais laiks</td>
                {features[TenantFeature.Units] && <td>Vienības</td>}
                {features[TenantFeature.Salary] && <td>Nopelnītais</td>}
                <td>Datums</td>
              </tr>
            </thead>
            <tbody>
              {op.tasks.map((task) => (
                <tr key={task.id}>
                  <td>{task.user.name}</td>
                  <td>{formatTimeDelta(task.timeUsed)}</td>
                  {features[TenantFeature.Units] && (
                    <td>{formatUnits(task.unitsUsed, true)}</td>
                  )}
                  {features[TenantFeature.Salary] && (
                    <td>{formatUnits(task.salaryUsed)}</td>
                  )}
                  <td>{formatTime(task.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
