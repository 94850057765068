import styled from "styled-components"
import { Button } from "../../../../components/button/Button"
import { Warning } from "../../../../components/icons/Warning"
import { useState } from "react"
import { Input } from "../../../../components/form/input"
import { toast } from "react-toastify"
import { Task } from "../../../../../types"
import { reportDefect } from "../../../../fetch/tasks"

const BottomSheet = styled.div<{ open: boolean }>`
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  display: flex;
  justify-content: center;
  top: ${(p) => (p.open ? "0" : "100%")};
  transition: top 0.2s ease;
  z-index: 9;
`
const InnerWrap = styled.div`
  max-width: 800px;
  min-width: 400px;

  button {
    width: 100%;
    height: auto;
  }
`

const InputWrap = styled.div`
  margin: 12px 0;
  flex-direction: column;
  display: flex;
  gap: 12px;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const RegisterDefect = ({
  onSave,
  taskId,
}: {
  onSave: () => void
  taskId: Task["id"]
}) => {
  const [open, setOpen] = useState(false)
  const [comment, setComment] = useState("")
  const [target, setTarget] = useState("")

  const handleSave = async () => {
    try {
      // @TODO
      // FETCH AND SAVE

      const re = await reportDefect({ operationId: +taskId, comment, target })
      if (!re.ok) {
        throw new Error("")
      }
      // can use `taskId` here
      // then
      toast("Defekts veiksmīgi reģistrēts", { type: "success" })
    } catch (err) {
      //
      toast("Defektu nav izdevies reģistrēt, lūdzu mēģiniet vēlreiz", {
        type: "error",
      })
      return
    }

    setOpen(false)
    setComment("")
    setTarget("")
    onSave()
  }
  return (
    <>
      <Button variant="secondary" onClick={() => setOpen(true)}>
        <Warning />
        Reģistrēt defektu
      </Button>
      <BottomSheet open={open}>
        <InnerWrap>
          <Title>Reģistrēt defektu</Title>
          <InputWrap>
            <Input
              value={comment}
              placeholder="Ievadīt"
              label="Komentārs"
              onChange={(e) => setComment(e.target.value)}
            />
            <Input
              value={target}
              placeholder="Norādiet iecirkni vai procesu"
              label="Kur atgriezt vienību"
              onChange={(e) => setTarget(e.target.value)}
            />
          </InputWrap>
          <ButtonsWrap>
            <Button onClick={handleSave}>Saglabāt</Button>
            <Button variant="secondary" onClick={() => setOpen(false)}>
              Atcelt
            </Button>
          </ButtonsWrap>
        </InnerWrap>
      </BottomSheet>
    </>
  )
}
