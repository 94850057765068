import React from "react"
import classNames from "classnames"
import { District } from "../../../../types"
import { Button } from "../../../components/button/Button"
import styles from "../AdminTasks/sidepanel.module.css"
import {
  createDistrict,
  deleteDistrict,
  updateDistrict,
} from "../../../fetch/districts"
import { Input } from "../../../components/form/input"
import { DeleteIcon, IconButton } from "../../../components/button/IconButton"

type Props = {
  district?: Partial<District>
  onClose: (updated?: boolean) => void
  setEditValue<k extends keyof District>(field: k, value: District[k]): void
}

export const EditDistrict = ({ district, onClose, setEditValue }: Props) => {
  const onDelete = (id: number) => {
    deleteDistrict(id).then(() => {
      onClose(true)
    })
  }

  const handleEditSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (district != null) {
    }
    if (district?.id == null) {
      if (district?.name?.trim?.() == "") {
        return
      }
      createDistrict(district as District).then(() => {
        onClose(true)
      })
    } else {
      updateDistrict(district.id, district).then(() => {
        onClose(true)
      })
    }
  }

  const shifts =
    3 * Math.min(district?.workers ?? 0, district?.concurrentShifts ?? 1)
  const hours = shifts * (district?.shift ?? 0)

  return (
    <div className={classNames(styles.sidepanel, district ? styles.open : "")}>
      <div className={classNames(styles.wrap, styles.flexCol)}>
        <div className={styles.close}>
          <Button variant="inline" onClick={() => onClose()}>
            Aizvērt
          </Button>
        </div>

        <form onSubmit={handleEditSubmit} className="district-sidebar-content">
          <div>
            <Input
              type="text"
              label="Iecirknis"
              onChange={(e) => setEditValue("name", e.target.value)}
              value={district?.name}
            />
          </div>
          <div className={styles.blocks}>
            <div className={styles.taskRow}>
              <Input
                type="text"
                label="Darbinieku skaits"
                onChange={(e) =>
                  setEditValue("workers", Number(e.target.value) ?? 0)
                }
                value={district?.workers}
              />
            </div>
            <div className={styles.taskRow}>
              <Input
                type="text"
                label="Maiņas ilgums"
                onChange={(e) =>
                  setEditValue("shift", Number(e.target.value) ?? 0)
                }
                value={district?.shift}
              />
            </div>
          </div>
          <div>
            <Input
              type="text"
              label="Maksimālais maiņu skaits dienā"
              onChange={(e) =>
                setEditValue("concurrentShifts", Number(e.target.value) ?? 0)
              }
              value={district?.concurrentShifts}
            />
          </div>
          <div
            className="district-description-text"
            style={{ marginTop: "16px" }}
          >
            Kopējais pieejamais 3 dienu resurss: {shifts} maiņas jeb {hours}h
          </div>
        </form>

        <div className={styles.buttons}>
          {district?.id != null ? (
            <IconButton onClick={() => onDelete(district.id as number)}>
              {DeleteIcon}
            </IconButton>
          ) : null}
          <div style={{ display: "flex", gap: 8 }}>
            <Button variant="secondary" onClick={() => onClose()}>
              Atcelt
            </Button>
            <Button
              onClick={handleEditSubmit}
              disabled={(district?.name?.trim?.().length ?? 0) < 1}
            >
              Saglabāt
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
