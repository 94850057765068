import styled from "styled-components"

const DatesRoot = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(9, 40px);
  margin: 10px auto 30px;
  justify-content: center;
`
const DateItem = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  opacity: 0.7;
  user-select: none;

  &:hover {
    opacity: 1;
  }
`

const DateNumber = styled.div<{ selected: boolean }>`
  font-size: 14px;
  font-weight: bold;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  ${({ selected }) => (selected ? "background: var(--iris); color: #fff;" : "")}
`
const DateText = styled.div`
  font-size: 11px;
  color: #999999;
`

const DAYS = ["Sv", "P", "O", "T", "C", "Pk", "S"]

const createDates = (selected: string) =>
  new Array(9)
    .fill(null)
    .map((_, i) => {
      const d = new Date(selected)
      d.setDate(d.getDate() - i + 4)
      return {
        date: new Date(d),
        formatted: `${d.getFullYear()}-${(d.getMonth() + 1 + "").padStart(2, "0")}-${(d.getDate() + "").padStart(2, "0")}`,
        day: d.getDate(),
      }
    })
    .reverse()

export const Dates = ({
  onSelect,
  selected,
}: {
  selected: string
  onSelect(date: string): void
}) => {
  const dates = createDates(selected)

  return (
    <DatesRoot>
      {dates.map((d) => {
        return (
          <DateItem onClick={() => onSelect(d.formatted)} key={d.formatted}>
            <DateNumber selected={d.formatted === selected}>{d.day}</DateNumber>
            <DateText>{DAYS[d.date.getDay()]}</DateText>
          </DateItem>
        )
      })}
    </DatesRoot>
  )
}
